import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import CookieConsent from "react-cookie-consent"

const GdprCompliance = () => (
  <CookieConsent
    location="bottom"
    buttonText="Accept"
    declineButtonText="Decline"
    cookieName="gatsby-gdpr-google-analytics"
    enableDeclineButton
    flipButtons
    declineButtonStyle={{
      fontFamily: "HK Grotesk Normal",
      fontSize: 16,
      color: "white",
      background: "none",
      borderRadius: 4,
      padding: "10px 16px",
      textTransform: "uppercase",
    }}
    buttonStyle={{
      fontFamily: "HK Grotesk Normal",
      fontSize: 16,
      color: "white",
      background: "#1890ff",
      borderRadius: 4,
      padding: "10px 16px",
      textTransform: "uppercase",
    }}
    style={{
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      cursor: "not-allowed",
      fontSize: 12,
    }}
  >
    This website uses cookies to enhance the user experience.&nbsp; These
    cookies are used to collect information about how you interact with our
    website and allow us to remember you. We use this information in order to
    improve and customize your browsing experience and for analytics and metrics
    about our visitors both on this website and other media. If you decline,
    your information will not be tracked when you visit this website. A single
    cookie will be used in your browser to remember your preference not to be
    tracked.
  </CookieConsent>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Mobilenurse" />
    <Navigation />
    <Header />
    <Features />
    <GetStarted />
    <Footer />
    <GdprCompliance />
  </Layout>
)

export default IndexPage
